@media screen and (min-width:800px) {
    /* FOOTER */
    .footer {
        text-align: center;
    }
    .footer .brand-image {
        margin: auto;
    }
    .legible-type-footer {
        max-width: 40em;
        margin: auto;
    }
    .legible-type-footer li {
        list-style-type: none;
    }
}